.PlayerGroup {
  display: flex;
  flex-direction: row;
  padding: .5rem;
  position: relative;
  z-index: -1;

  @media (orientation: landscape) {

    // BEG Clock left and right.
    @media (max-height: 812px) {
      background: #9c6d4d url("../../../img/clockBg@1x.png") no-repeat center center scroll;
      background-size: cover;
      box-shadow: inset -1px 2px 2px rgba(255,255,200, .5),  inset 1px -2px 2px rgba(0,0,0, .5),  -5px 5px 12px rgba(0,0,0,.5);

      &.White {
        transform: translateX(-115%);
      }
      &.Black {
        transform: translateX(115%);
      }
    }

    // END Clock left and right.
  }


  input[type="checkbox"] {
    display: none;
  }

  label {
    line-height: calc(1.5rem + 2px);
    color: gold;
    font-style: italic;

    @media (orientation: landscape) {
      margin: 0 1vh;
    }
    @media (orientation: portrait) {
      margin: 0 1vw;
    }

    > span {
      // Reset spacing form-element.
      margin: 0;
      padding: 0;
      height: 36px;
      width: 36px;
      background: transparent url("../../../img/computed@1x.png") no-repeat center center scroll;
      color: #333;

      &:before {
        font-size: 1rem;
        line-height: 36px;
        margin: 0;
        width: 36px;
      }
    }
  }

  > span {
    display: none;
  }

  .Status {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 0;
    color: gold;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.6666rem;
    position: relative;
    padding-top: 5%;


    @media (orientation: landscape) {
      @media (min-height: 568px) {
        width: 4rem;
        height: 4rem;
        font-size: 2.3333rem;
      }
      @media (min-height: 813px) {
        width: 5rem;
        height: 5rem;
        font-size: 2.666rem;
      }
    }
    @media (orientation: portrait) {
      margin: 0 1vw;
      @media (min-width: 568px) {
        width: 4rem;
        height: 4rem;
        font-size: 2.3333rem;
      }
      @media (min-width: 813px) {
        width: 5rem;
        height: 5rem;
        font-size: 2.666rem;
      }
    }

    &:before {
      content: '';
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      width: 95%;
      height: 95%;
      top: 2.5%;
      left: 2.5%;
      background-color: #ffffcc;
      box-shadow: inset -2px 3px 6px rgba(0,0,0,.99);
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent url("../../../img/blend@1x.png") no-repeat center center scroll;
      background-size: contain;
    }
  }

  label.icon-crown {
    position: absolute;
    bottom: 50%;
    white-space: nowrap;
    background-color: #333333;
    padding: .5em 1em;
    border-radius: 5px;
    font-style: normal;
    box-shadow: -5px 5px 12px rgba(0,0,0,.5);

    @media (orientation: landscape) and (max-height: 812px) {
      bottom: -1rem;
      margin: 0;
    }
  }

  &.White {
    flex-direction: row-reverse;
    left: 0;
    .Status {
      color: #333;
    }

    label.icon-crown {
      left: -1rem;
      transform: translate(-100%, 50%);

      @media (orientation: landscape) and (max-height: 812px) {
        left: auto;
        right: 0;
        transform: translate(0,100%);
      }
    }
  }
  &.Black {
    right: 0;
    .Status {
      &:before {
        background-color: #333333;
      }
    }

    label.icon-crown {
      right: -1rem;
      transform: translate(100%, 50%);


      @media (orientation: landscape) and (max-height: 812px) {
        left: 0;
        right: auto;
        transform: translate(0,100%);
      }
    }
  }
}