.MainMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  min-width: 3rem;


  &Toggle {
    display: block;
    font-size: 1.25rem;
    width: 3rem;
    height: 3rem;
    color: gold;
    line-height: 3rem;
  }

  &Wrap {
    position: absolute;
    width: 220px;
    z-index: 25;
    overflow: auto;

    top: 3rem;
    left: 0;
    transform: translateX(-100%);
    background: #333;
    padding: 0 0 .5rem 0;
    height: calc(100vh - 3rem);
    transition: transform .8s cubic-bezier(0.19, 1, 0.22, 1);

    picture {
      display: block;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    h1 {
      font-size: 2rem;
      letter-spacing: .1em;
      color: #FFF;
      margin: 0;
      position: relative;
      top: -3rem;
    }
  }

}

#MainMenuCheckbox:checked {
  + .MainMenuWrap {
    transform: translateX(0);
  }
}


ul.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0 1rem;

  }

  a {
    color: gold;
    display: block;
    padding: 0 1rem;
    font-size: 1.25rem;
    line-height: 2em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

ul.social {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    padding: 0 .5rem;
  }

  a {
    color: #FFFFFF;
    text-decoration: none;
    display: inline-block;
    line-height: 2rem;

    &:hover {
      text-decoration: underline;
    }
  }
}