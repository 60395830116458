.form {

  &-element {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.fe {
  &-checkbox {
    line-height: 2em;
    position: relative;
    font-size: .875rem;
    cursor: pointer;

    span {
      display: block;
      margin: 5px;
      padding: 0 .5em;
      font-size: 1.125rem;
      background-color: #333;
      border-radius: .2rem;
      color: gold;
    }

    input[type=checkbox] {
      display: none;

      &:active {

        > span {
          outline: 2px #666;
        }
      }
    }
  }

  &-password {
    flex-direction: column;
    margin: 1rem 0;

    label {
      font-size: 1.5rem;
      margin: .5rem 0;
    }

    input {
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      text-align: center;
      
      &:active,
      &:focus {
        outline: 0;
        border-color: #333;
      }
    }
  }
}