
.Overlay {
  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(255,255,255,.9);
  overflow: auto;

  .container {
    min-height: 100%;
  }

  .close {
    position: fixed;
    top: 5rem;
    right: 3rem;
    width: 32px;
    height: 32px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='close'%3E%3Cpath id='x' d='M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center scroll;
  }

  p, ol, ul, table, span, strong {
    font-family: "Times New Roman", "Book Antiqua", serif;
  }
}