
.History {
  display: none;
  transition: all .3s ease-in-out;

  @media all and (min-width: 813px){
    display: block;
    position: relative;
    overflow: hidden;
    flex: 1 1 0;
    width: 0;

    &.active {

      flex: 1 1 15%;
      width: 15%;
    }
  }
}


.HistoryTable {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotate(-6deg);
  -webkit-backface-visibility: hidden;
  height: 80%;
  width: 100%;
  max-height: 580px;

  background: #ffffaa url("../../img/legalPad.svg") no-repeat top left scroll;
  box-shadow: -3px 3px 3px rgba(0,0,0,.4) ;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin: 0;
  padding: 3.25rem 1rem 0;
  overflow: hidden;
  order: 2;

  *, &:before, &:after {
    // line smoothing in chrome caused by rotate.
    -webkit-backface-visibility: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background: #333 url("../../img/legalPadBind.png") repeat-x top left scroll;
    box-shadow: -3px 3px 3px rgba(0,0,0,.4) ;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background-color: #ffffaa;
  }
}