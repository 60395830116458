.Cell {
  padding-top: 12.5%;
  flex: 1 1 12.5%;
  position: relative;
  background-color: #A97E4E;

  span.tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0 0 2px #FFF;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    z-index: 1;
    background-color: rgba(0,0,0,.3);
    border-radius: 50%;
    transition: all .5s ease-in-out;
    transform: translate(-50%, -50%);
  }

  &.White::after {
    background-color: #9c6d4d;
    transform: translate(-50%, -50%) rotate3d(1,-1,0, 0deg);
  }


  &.Black::after {
    background-color: #3f2826;
    transform: translate(-50%, -50%) rotate3d(1,-1,0, 180deg);
  }

  &.PossibleWhite::after {
    background-color: rgba(255,255,255,.4);
  }

  &.PossibleBlack::after {
    background-color: rgba(0,0,0,.2);
  }

}

.Cell {
  background: no-repeat center center scroll;
  background-size: 100% 100%;

  &::after {
    background: no-repeat center center scroll;
    background-size: cover;
  }

  &.Black, &.White {
    &::after {
      border: 0;
      box-shadow: -5px 3px 10px rgba(0, 0, 0, 0.5);
    }
  }

  // Cells
  &:nth-child(16n+1) { background-image: url("../../../img/dark1@1x.png"); }
  &:nth-child(16n+2) { background-image: url("../../../img/light3@1x.png");}
  &:nth-child(16n+3) { background-image: url("../../../img/dark2@1x.png"); }
  &:nth-child(16n+4) { background-image: url("../../../img/light4@1x.png");}
  &:nth-child(16n+5) { background-image: url("../../../img/dark3@1x.png"); }
  &:nth-child(16n+6) { background-image: url("../../../img/light5@1x.png");}
  &:nth-child(16n+7) { background-image: url("../../../img/dark4@1x.png"); }
  &:nth-child(16n+8) { background-image: url("../../../img/light1@1x.png");}
  &:nth-child(16n+9) { background-image: url("../../../img/light2@1x.png");}
  &:nth-child(16n+10){ background-image: url("../../../img/dark5@1x.png");}
  &:nth-child(16n+11){ background-image: url("../../../img/light3@1x.png");}
  &:nth-child(16n+12){ background-image: url("../../../img/dark1@1x.png");}
  &:nth-child(16n+13){ background-image: url("../../../img/light4@1x.png");}
  &:nth-child(16n+14){ background-image: url("../../../img/dark2@1x.png");}
  &:nth-child(16n+15){ background-image: url("../../../img/light5@1x.png");}
  &:nth-child(16n)   { background-image: url("../../../img/dark3@1x.png");}


  // Tokens
  &:nth-child(12n) {
    &.Black::after { background-image: url("../../../img/black1@1x.png");}
    &.White::after { background-image: url("../../../img/white1@1x.png"); }
  }

  &:nth-child(12n+1) {
    &.Black::after { background-image: url("../../../img/black2@1x.png"); }
    &.White::after { background-image: url("../../../img/white2@1x.png"); }
  }

  &:nth-child(12n+2) {
    &.Black::after { background-image: url("../../../img/black3@1x.png"); }
    &.White::after { background-image: url("../../../img/white3@1x.png"); }
  }

  &:nth-child(12n+3) {
    &.Black::after { background-image: url("../../../img/black4@1x.png"); }
    &.White::after { background-image: url("../../../img/white4@1x.png"); }
  }

  &:nth-child(12n+4) {
    &.Black::after { background-image: url("../../../img/black5@1x.png"); }
    &.White::after { background-image: url("../../../img/white5@1x.png"); }
  }

  &:nth-child(12n+5) {
    &.Black::after { background-image: url("../../../img/black6@1x.png"); }
    &.White::after { background-image: url("../../../img/white6@1x.png"); }
  }

  &:nth-child(12n+6) {
    &.Black::after { background-image: url("../../../img/black7@1x.png"); }
    &.White::after { background-image: url("../../../img/white7@1x.png"); }
  }

  &:nth-child(12n+7) {
    &.Black::after { background-image: url("../../../img/black8@1x.png"); }
    &.White::after { background-image: url("../../../img/white8@1x.png"); }
  }

  &:nth-child(12n+8) {
    &.Black::after { background-image: url("../../../img/black9@1x.png"); }
    &.White::after { background-image: url("../../../img/white9@1x.png"); }
  }

  &:nth-child(12n+9) {
    &.Black::after { background-image: url("../../../img/black10@1x.png"); }
    &.White::after { background-image: url("../../../img/white10@1x.png"); }
  }

  &:nth-child(12n+10) {
    &.Black::after { background-image: url("../../../img/black11@1x.png"); }
    &.White::after { background-image: url("../../../img/white11@1x.png"); }
  }

  &:nth-child(12n+11) {
    &.Black::after { background-image: url("../../../img/black12@1x.png"); }
    &.White::after { background-image: url("../../../img/white12@1x.png"); }
  }

  @media (min-width: 813px) {

    // Cells
    &:nth-child(16n+1) { background-image: url("../../../img/dark1@2x.png"); }
    &:nth-child(16n+2) { background-image: url("../../../img/light3@2x.png");}
    &:nth-child(16n+3) { background-image: url("../../../img/dark2@2x.png"); }
    &:nth-child(16n+4) { background-image: url("../../../img/light4@2x.png");}
    &:nth-child(16n+5) { background-image: url("../../../img/dark3@2x.png"); }
    &:nth-child(16n+6) { background-image: url("../../../img/light5@2x.png");}
    &:nth-child(16n+7) { background-image: url("../../../img/dark4@2x.png"); }
    &:nth-child(16n+8) { background-image: url("../../../img/light1@2x.png");}
    &:nth-child(16n+9) { background-image: url("../../../img/light2@2x.png");}
    &:nth-child(16n+10){ background-image: url("../../../img/dark5@2x.png");}
    &:nth-child(16n+11){ background-image: url("../../../img/light3@2x.png");}
    &:nth-child(16n+12){ background-image: url("../../../img/dark1@2x.png");}
    &:nth-child(16n+13){ background-image: url("../../../img/light4@2x.png");}
    &:nth-child(16n+14){ background-image: url("../../../img/dark2@2x.png");}
    &:nth-child(16n+15){ background-image: url("../../../img/light5@2x.png");}
    &:nth-child(16n)   { background-image: url("../../../img/dark3@2x.png");}

    // Tokens
    &:nth-child(12n) {
      &.Black::after { background-image: url("../../../img/black1@2x.png");}
      &.White::after { background-image: url("../../../img/white1@2x.png"); }
    }

    &:nth-child(12n+1) {
      &.Black::after { background-image: url("../../../img/black2@2x.png"); }
      &.White::after { background-image: url("../../../img/white2@2x.png"); }
    }

    &:nth-child(12n+2) {
      &.Black::after { background-image: url("../../../img/black3@2x.png"); }
      &.White::after { background-image: url("../../../img/white3@2x.png"); }
    }

    &:nth-child(12n+3) {
      &.Black::after { background-image: url("../../../img/black4@2x.png"); }
      &.White::after { background-image: url("../../../img/white4@2x.png"); }
    }

    &:nth-child(12n+4) {
      &.Black::after { background-image: url("../../../img/black5@2x.png"); }
      &.White::after { background-image: url("../../../img/white5@2x.png"); }
    }

    &:nth-child(12n+5) {
      &.Black::after { background-image: url("../../../img/black6@2x.png"); }
      &.White::after { background-image: url("../../../img/white6@2x.png"); }
    }

    &:nth-child(12n+6) {
      &.Black::after { background-image: url("../../../img/black7@2x.png"); }
      &.White::after { background-image: url("../../../img/white7@2x.png"); }
    }

    &:nth-child(12n+7) {
      &.Black::after { background-image: url("../../../img/black8@2x.png"); }
      &.White::after { background-image: url("../../../img/white8@2x.png"); }
    }

    &:nth-child(12n+8) {
      &.Black::after { background-image: url("../../../img/black9@2x.png"); }
      &.White::after { background-image: url("../../../img/white9@2x.png"); }
    }

    &:nth-child(12n+9) {
      &.Black::after { background-image: url("../../../img/black10@2x.png"); }
      &.White::after { background-image: url("../../../img/white10@2x.png"); }
    }

    &:nth-child(12n+10) {
      &.Black::after { background-image: url("../../../img/black11@2x.png"); }
      &.White::after { background-image: url("../../../img/white11@2x.png"); }
    }

    &:nth-child(12n+11) {
      &.Black::after { background-image: url("../../../img/black12@2x.png"); }
      &.White::after { background-image: url("../../../img/white12@2x.png"); }
    }
  }

}
