.Ruler {
  font-family: 'afterglow', serif;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  font-size: .9375rem;
  line-height: 1rem;
  border-radius: 7px;

  &.top, &.bottom {
    left: 0;
    right: 0;
    padding: 0 5px;
    height: 5px;
    background: #9c6d4d url("../../../img/boardBorderH@1x.png") no-repeat center center scroll;
    background-size: cover;

    @media (orientation: portrait) and  (min-width: 813px) {
      background-image: url("../../../img/boardBorderH@2x.png");
      padding: 0 1rem;
      height: 1rem;
    }
    @media (orientation: landscape) and (min-height: 813px) {
      background-image: url("../../../img/boardBorderH@2x.png");
      padding: 0 1rem;
      height: 1rem;
    }
  }

  &.left, &.right {
    flex-direction: column;
    top: 0;
    bottom: 0;
    padding: 5px 0;
    width: 5px;
    background: #9c6d4d url("../../../img/boardBorder@1x.png") no-repeat center center scroll;
    background-size: cover;


    @media (orientation: portrait) and  (min-width: 813px) {
      background-image: url("../../../img/boardBorder@2x.png");
      padding: 1rem 0;
      width: 1rem;
    }
    @media (orientation: landscape) and (min-height: 813px) {
      background-image: url("../../../img/boardBorder@2x.png");
      padding: 1rem 0;
      width: 1rem;
    }
  }

  &.top { top: 0; }
  &.bottom { bottom: 0; }
  &.left { left: 0; }
  &.right { right: 0; }

  li {
    flex: 1 1 12.5%;
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none;
    color: #eee371;

    justify-content: center;
    align-items: center;

    position: relative;
    z-index: 2;


    display: none;
    @media (orientation: portrait) and (min-width: 813px) {
      display: flex;
    }
    @media (orientation: landscape) and (min-height: 813px) {
      display: flex;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #333333;
    }
  }


  &.left,
  &.right {
    li:before {

      transform: translate(-50%, -60%);
    }
  }
}
