.Plate {
  position: relative;
  width:  80%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 7px;
  box-shadow: -5px 5px 12px rgba(0,0,0,.5);

  @media (orientation: landscape) {
    width: calc(95vh - 48px);
  }
  @media (orientation: portrait) {
    width: 95vw;
  }

  @media (orientation: portrait) and  (min-width: 813px) {
    padding: 1rem;
    width: 80vw;
  }
  @media (orientation: landscape) and (min-height: 813px) {
    padding: 1rem;
    width: calc(80vh - 48px);
  }
}

.Board {
  background-color: #A97E4E;
  border: 1px solid #A97E4E;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:  100%;
  position: relative;
}
