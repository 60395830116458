.Message {
  position: fixed;
  left: 50%;
  top: 3rem;
  z-index: 10;
  width: 80vw;
  max-width: 400px;

  padding: 1rem;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 15px rgba(0,0,0,.2);
  transform: translate(-50%, -110%);
  animation: message 5s cubic-bezier(0.19, 1, 0.22, 1);

  &.status {
    border: 2px solid #718C00;
    border-top: 0;
    background-color: lighten(#718C00, 50%);
  }
  &.warn {
    border: 2px solid #f19d00;
    border-top: 0;
    background-color: lighten(#f19d00, 50%);
  }
  &.error {
    border: 2px solid #c60002;
    border-top: 0;
    background-color: lighten(#c60002, 50%);
  }

  @keyframes message {
    0%   {transform: translate(-50%, -110%);}
    15%  {transform: translate(-50%, 0%);}
    85%  {transform: translate(-50%, 0%);}
    100% {transform: translate(-50%, -110%);}
  }

  .close {
    position: absolute;
    top: .25rem;
    right: .25rem;
    display: block;
    width: 1rem;
    height: 1rem;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #999;
      border-radius: 1px;
    }
    &:before {
      transform: translate(-50%, -1px) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -1px) rotate(-45deg);
    }
  }
}
