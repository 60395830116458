.Auth {
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  
  form {
    padding: 1.5rem;
    background-color: #333333;
    
    label {
      color: #FFF;
    }
  }
}