.TipsTable {
  width: 80%;
  max-width: 960px;
  margin: 2rem auto;

  header {
    background-color: black;
    color: gold;
    padding: .25rem;
  }
}