@keyframes fadein {
  from {
    transform: translateY(-100%);
    margin-bottom: -24px;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    margin-bottom: 0;
    opacity: 1;
  }
}

.HistItem {
  position: relative;
  font-family: 'afterglow', serif;
  font-size: 1.25rem;
  text-transform: lowercase;
  letter-spacing: .2em;
  line-height: 1.25rem;
  padding: 0 0 0 2rem;
  list-style: none;
  text-align: left;
  animation-name: fadein;
  animation-duration: .5s;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width:  1rem;
    border: 1px solid #777;
    border-radius: 100%;
  }

  &.Black:before {
    background-color: #333;
  }
  &.White:before {
    background-color: #FFF;
  }
}