
.Controlls {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 85%;
  max-width: 960px;
  margin: 0 0 0 15%;

  @media all and (min-width: 813px){
    width: 80%;
    max-width: 960px;
    margin: 0 auto;
  }
}
