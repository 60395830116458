.Accounts {
  z-index: 7;
  max-width: 100vw;
  position: relative;

  // LANDSCAPE
  @media (orientation: landscape) {


    /// BEG Clock left and right.
    @media (max-height: 812px) {
      width: calc(95vh - 48px);
      z-index: 0;
    }
    /// END Clock left and right.
  }

  .Clock {
    position: absolute;
    left: 50%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translate(-50%, -110%);
    z-index: -1;
    border-radius: 4px;

    @media (orientation: portrait) {
      transform: translate(-50%, -10%);
      position: relative;
      background: #9c6d4d url("../../img/clockBg@2x.png") no-repeat center center scroll;
      background-size: cover;
      box-shadow: inset -1px 2px 2px rgba(255,255,200, .5),  inset 1px -2px 2px rgba(0,0,0, .5),  -5px 5px 12px rgba(0,0,0,.5);
    }

    @media (orientation: landscape) {
      @media (min-height: 813px) {
        transform: translate(-50%, -10%);
        position: relative;
        justify-content: space-between;
        background: #9c6d4d url("../../img/clockBg@2x.png") no-repeat center center scroll;
        background-size: cover;
        box-shadow: inset -1px 2px 2px rgba(255,255,200, .5),  inset 1px -2px 2px rgba(0,0,0, .5),  -5px 5px 12px rgba(0,0,0,.5);
      }

      // BEG Clock left and right.
      @media (max-height: 812px) {
        width: 100%;
        transform: translate(-50%, 12vh);
        justify-content: space-between;
      }
      // END Clock left and right.
    }


  }
}
