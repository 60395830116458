@import "scss/form";
@import "scss/fontello";
@import "font/font";

.App {
  text-align: center;
  font-family: 'afterglow', serif;
  background: #10822b url("./img/textureGreen.jpg") repeat center center scroll;
  box-shadow: inset 0 0 200px rgba(0,0,0,.5);
  min-height: 100vh;

  &Header {
    position: sticky;
    top: 0;
    z-index: 15;
    height: 48px;

    background-color: #333333;
  }
}

.MainSection {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 48px);
  max-width: 100vw;
  overflow: hidden;
}

.Game {
  flex: 1 1 100%;
  position: relative;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-size: 1.25rem;
}

.copyright {
  text-align: center;
  color: #AAA;
  font-size: .625rem;
  margin-bottom: .25rem;
}

.Btn {
  display: inline-block;
  font-family: 'afterglow', serif;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .125rem .5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .2rem;
  color: gold;
  background-color: #333;
  border: 1px solid #333;
  margin: 5px;

  &:hover {
     background-color: #5a6268;
     border-color: #545b62;
  }
  &:focus {
     outline: 0;
     box-shadow: 0 0 0 0.2rem rgba(108,117,125,.5);
  }
  &[disabled] {
    color: #AAA;
  }
}


table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

th,
td {
  padding: .5rem;
  color: #000;
}

th {
  text-align: left;
}

thead {
  th {
    background-color: #55608f;
    color: #FFF;
    white-space: nowrap;
  }
}

tbody {
  tr {
    &:nth-child(2n) {
      background-color: #efefef;
    }

    &:hover {
      background-color: rgba(1,1,1,0.3);
    }
  }
  td {
    text-align: left;
    position: relative;
  }
}
