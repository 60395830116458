
.Winner {
  position: absolute;
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background-color: #FFF;
  box-shadow: 2px 2px 5px rgba(0,0,0,.5);
  font-style: italic;

  span {
    font-size: 1.5rem;
    background-color: #FFF;
    display: block;
    padding: 2rem 1.5rem;
    border: 1px solid #111;
    white-space: nowrap;
  }

  &.Black {

    background-color: #111;
    span {
      border: 1px solid #EEE;
      color: #FFF;
      background-color: #111;
    }
  }
  &.Remis {
    background-color: #111;
  }
}