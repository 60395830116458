@charset "UTF-8";
.form-element {
  display: flex;
  flex-direction: row;
  align-items: center; }

.fe-checkbox {
  line-height: 2em;
  position: relative;
  font-size: .875rem;
  cursor: pointer; }
  .fe-checkbox span {
    display: block;
    margin: 5px;
    padding: 0 .5em;
    font-size: 1.125rem;
    background-color: #333;
    border-radius: .2rem;
    color: gold; }
  .fe-checkbox input[type=checkbox] {
    display: none; }
    .fe-checkbox input[type=checkbox]:active > span {
      outline: 2px #666; }

.fe-password {
  flex-direction: column;
  margin: 1rem 0; }
  .fe-password label {
    font-size: 1.5rem;
    margin: .5rem 0; }
  .fe-password input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-align: center; }
    .fe-password input:active, .fe-password input:focus {
      outline: 0;
      border-color: #333; }

@font-face {
  font-family: 'fontello';
  src: url("./font/fontello.eot?90609580");
  src: url("./font/fontello.eot?90609580#iefix") format("embedded-opentype"), url("./font/fontello.woff2?90609580") format("woff2"), url("./font/fontello.woff?90609580") format("woff"), url("./font/fontello.ttf?90609580") format("truetype"), url("./font/fontello.svg?90609580#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?90609580#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-new:before {
  content: '\e800'; }

/* '' */
.icon-info:before {
  content: '\e801'; }

/* '' */
.icon-undo:before {
  content: '\e802'; }

/* '' */
.icon-back:before {
  content: '\e804'; }

/* '' */
.icon-error:before {
  content: '\e805'; }

/* '' */
.icon-forward:before {
  content: '\e806'; }

/* '' */
.icon-ffw:before {
  content: '\e807'; }

/* '' */
.icon-laptop:before {
  content: '\e808'; }

/* '' */
.icon-alert:before {
  content: '\e80a'; }

/* '' */
.icon-cog:before {
  content: '\e80b'; }

/* '' */
.icon-crown:before {
  content: '\e844'; }

/* '' */
.icon-menu:before {
  content: '\f008'; }

/* '' */
.icon-calc:before {
  content: '\f1ec'; }

/* '' */
.icon-off:before {
  content: '\f204'; }

/* '' */
.icon-on:before {
  content: '\f205'; }

/* '' */
.icon-user:before {
  content: '\f2be'; }

/* '' */
@font-face {
  font-family: 'afterglow';
  src: url("./font/webfont.woff2") format("woff2"), url("./font/webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.App {
  text-align: center;
  font-family: 'afterglow', serif;
  background: #10822b url("./img/textureGreen.jpg") repeat center center scroll;
  box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.5);
  min-height: 100vh; }
  .AppHeader {
    position: sticky;
    top: 0;
    z-index: 15;
    height: 48px;
    background-color: #333333; }

.MainSection {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 48px);
  max-width: 100vw;
  overflow: hidden; }

.Game {
  flex: 1 1 100%;
  position: relative;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-size: 1.25rem; }

.copyright {
  text-align: center;
  color: #AAA;
  font-size: .625rem;
  margin-bottom: .25rem; }

.Btn {
  display: inline-block;
  font-family: 'afterglow', serif;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .125rem .5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .2rem;
  color: gold;
  background-color: #333;
  border: 1px solid #333;
  margin: 5px; }
  .Btn:hover {
    background-color: #5a6268;
    border-color: #545b62; }
  .Btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .Btn[disabled] {
    color: #AAA; }

table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }

th,
td {
  padding: .5rem;
  color: #000; }

th {
  text-align: left; }

thead th {
  background-color: #55608f;
  color: #FFF;
  white-space: nowrap; }

tbody tr:nth-child(2n) {
  background-color: #efefef; }

tbody tr:hover {
  background-color: rgba(1, 1, 1, 0.3); }

tbody td {
  text-align: left;
  position: relative; }
